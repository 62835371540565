import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, catchError, map, throwError } from 'rxjs';
import { HttpRequestService } from './http-request.service';
import { HttpRequestConstants } from './http-requests-constants';
import { User, UserParams } from '../model/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  error = new Subject<string>();
  constructor(private httpRequestService: HttpRequestService) { }

  getUser(userId: number) {
    return this.httpRequestService.get(HttpRequestConstants.GET_POST_PUT_DELETE_USERS + '/' + userId)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(errorRes => {
          return throwError(() => new Error(errorRes));
        })
      )
  };

  getUsers(params: UserParams) {
    const {
      name,
      status,
      email,
      airlineId,
      startDate,
      endDate,
      employeeId,
      pageSize,
      currentPage,
      sortByColumn,
      ascending
    } = params;
    let searchParams = new HttpParams();
    searchParams = searchParams.append('name', name);
    searchParams = searchParams.append('status', status);
    searchParams = searchParams.append('email', email);
    searchParams = searchParams.append('airlineId', airlineId);
    searchParams = searchParams.append('createDateFrom', startDate);
    searchParams = searchParams.append('createDateTo', endDate);
    searchParams = searchParams.append('employeeId', employeeId);

    searchParams = searchParams.append('size', pageSize);
    searchParams = searchParams.append('page', currentPage);
    searchParams = searchParams.append('sort', sortByColumn + "," + (ascending ? 'asc' : 'desc'));

    return this.httpRequestService
      .getWithExplicitParams(HttpRequestConstants.GET_POST_PUT_DELETE_USERS, searchParams)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(errorRes => {
          return throwError(() => new Error(errorRes));
        })
      );
  }

  updateUser(user: User) {
    return this.httpRequestService.put(HttpRequestConstants.GET_POST_PUT_DELETE_USERS + '/' + user.userIdInt, user)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(errorRes => {
          return throwError(() => new Error(errorRes));
        })
      )
  };


  createUser(user: User) {
    return this.httpRequestService.post(HttpRequestConstants.GET_POST_PUT_DELETE_USERS, user)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(errorRes => {
          return throwError(() => new Error(errorRes));
        })
      )
  };

  suspendUsers(users: number[]) {
    return this.httpRequestService.post(HttpRequestConstants.GET_POST_PUT_DELETE_USERS_SUSPEND, {
      "userIds": users
    })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(errorRes => {
          return throwError(() => new Error(errorRes));
        })
      )
  };

  activateUsers(users: number[]) {
    return this.httpRequestService.post(HttpRequestConstants.GET_POST_PUT_DELETE_USERS_ACTIVATE, {
      "userIds": users
    })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(errorRes => {
          return throwError(() => new Error(errorRes));
        })
      )
  };

  resetPasswords(users: number[]) {
    return this.httpRequestService.post(HttpRequestConstants.GET_POST_PUT_DELETE_USERS_RESET_PASSWORD, {
      "userIds": users
    })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(errorRes => {
          return throwError(() => new Error(errorRes));
        })
      )
  };

  exportUsers(
    params: UserParams
  ) {
    const {
      name,
      status,
      email,
      airlineId,
      startDate,
      endDate,
      employeeId,
      sortByColumn,
      ascending
    } = params;
    let searchParams = new HttpParams();
    searchParams = searchParams.append('name', name);
    searchParams = searchParams.append('status', status);
    searchParams = searchParams.append('email', email);
    searchParams = searchParams.append('airlineId', airlineId);
    searchParams = searchParams.append('createDateFrom', startDate);
    searchParams = searchParams.append('createDateTo', endDate);
    searchParams = searchParams.append('employeeId', employeeId);
    searchParams = searchParams.append('sort', sortByColumn + "," + (ascending ? 'asc' : 'desc'));

    return this.httpRequestService
      .download(HttpRequestConstants.GET_POST_PUT_DELETE_USERS_EXPORT, searchParams)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(errorRes => {
          return throwError(() => new Error(errorRes));
        })
      );
  }

  registerUser(user: User) {
    return this.httpRequestService.post(HttpRequestConstants.GET_POST_PUT_DELETE_REGISTER_USER, user)
      .pipe(
        map(response => {
          return response;
        }),
        catchError(errorRes => {
          return throwError(() => new Error(errorRes));
        })
      )
  };

}

