
import { CanActivateFn, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { inject } from '@angular/core';
import { LoginAuthService } from '../services/login-auth-service';

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router:Router = inject(Router);
  const allowedRoles = route.data["allowedRoles"];
  const isAuthorized = inject(LoginAuthService).isAuthorized(allowedRoles);
  if (!isAuthorized) {
    router.navigate(['/accessDenied']);
  }
  return isAuthorized;
};

