import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { HttpRequestService } from './http-request.service';
import { HttpRequestConstants } from './http-requests-constants';
import { ConfigResponse } from '../model/config.model';
import { HTTP_REQUEST_WINDOW_TIME_CONSTANT } from '../common/constants/constant';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  constructor(private readonly httpRequestService: HttpRequestService) { }
  config$: Observable<ConfigResponse> | undefined;

  getConfig() {
    this.config$ = this.httpRequestService.get(HttpRequestConstants.GET_POST_PUT_DELETE_CONFIG)
      .pipe(shareReplay({ bufferSize: 1, refCount: false, windowTime: HTTP_REQUEST_WINDOW_TIME_CONSTANT }));
    return this.config$;
  };

}

